import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { c } from 'styles/valiables';

const AppFooterStyle = styled.footer`
  width: 100%;
  margin-top: auto;
  box-shadow: 0 -5px 5px 0 ${c.dark10};
  height: ${c.headerHeight};
  flex-shrink: 0;

  .page-active {
    color: ${c.secondary} !important;
    font-weight: 700;
  }
`;

export function AppFooter() {
  const navigate = useNavigate();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/auth') {
      setActiveTabIndex(0);
    } else if (location.pathname === '/auth/notification') {
      setActiveTabIndex(1);
    } else if (location.pathname.indexOf('/auth/order') >= 0) {
      setActiveTabIndex(2);
    }
  }, [location.pathname]);

  const changeTab = (v: number) => {
    setActiveTabIndex(v);
    if (v === 0) {
      navigate('/auth');
    } else if (v === 1) {
      navigate('/auth/notification');
    } else if (v === 2) {
      navigate('/auth/order');
    } else {
      navigate('/auth');
    }
  };

  return (
    <AppFooterStyle>
      <BottomNavigation
        style={{ height: '100%' }}
        showLabels
        value={activeTabIndex}
        onChange={(event, v: number) => changeTab(v)}
      >
        <BottomNavigationAction
          className={activeTabIndex === 0 ? 'page-active' : ''}
          label="ホーム"
          icon={<HomeRoundedIcon />}
        />
        <BottomNavigationAction
          className={activeTabIndex === 1 ? 'page-active' : ''}
          label="通知"
          icon={<NotificationsRoundedIcon />}
        />
        <BottomNavigationAction
          className={activeTabIndex === 2 ? 'page-active' : ''}
          label="配送指示"
          icon={<AssignmentRoundedIcon />}
        />
      </BottomNavigation>
    </AppFooterStyle>
  );
}
