import { Button } from '@mui/material';
import { SetStateAction, useState } from 'react';

// styles
import 'styles/CancelForm.scss';

type Props = {
  hideCancelForm: () => void;
};
export function CancelForm({ hideCancelForm }: Props) {
  const [reason, setReason] = useState('');

  const changeReason = (e: { target: { value: SetStateAction<string> } }) => {
    setReason(e.target.value);
  };

  const sendCancel = () => {
    console.log('sendCancel', reason);
  };
  return (
    <div className="cancel-form">
      <textarea
        name="cancel-reason"
        placeholder="キャンセル理由をご入力ください"
        id="cancel-reason"
        cols={30}
        rows={5}
        value={reason}
        onChange={changeReason}
      />
      <div className="cancel-btns">
        <Button className="cancel-btn" variant="outlined" disableElevation fullWidth color="primary" onClick={hideCancelForm}>
          もどる
        </Button>
        <Button className="cancel-btn" variant="contained" disableElevation fullWidth color="warning" onClick={sendCancel}>
          キャンセルを申請
        </Button>
      </div>
    </div>
  );
}
