import { Snackbar, Button } from '@mui/material';
import LogoSymbol from 'assets/svg/logo_symbol.svg';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { c } from 'styles/valiables';
import { nowString } from 'utils/datetime';

const AppHeaderStyle = styled.header`
  width: 100%;
  height: ${c.headerHeight};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 5px 5px 0 ${c.dark10};
  flex-shrink: 0;
  a {
    width: 45px;
    height: 45px;
    margin-right: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .app-header-btns {
    .btn {
      font-weight: 700;
      letter-spacing: 0.15em;
      &:not(:last-child) {
        margin-right: 3px;
      }
    }
  }
`;

export function AppHeader() {
  const [isOnDuty, setIsOnDuty] = useState(false);

  const [snackbar, setSnackbar] = useState({ show: false, message: '' });

  useEffect(() => {
    if (snackbar.show) {
      setTimeout(() => {
        setSnackbar({ show: false, message: '' });
      }, 3000);
    }
  }, [snackbar.show]);

  const onDutyClicked = () => {
    console.log('出勤・退勤', isOnDuty);
    setIsOnDuty((prevState) => !prevState);
    setSnackbar({
      show: true,
      message: `${nowString()} ${isOnDuty ? '退勤しました' : '出勤しました'}`,
    });
  };

  interface MuiButton {
    label: string
    variant: 'outlined' | 'contained'
    disableElevation?: boolean
    color?:
      | 'warning'
      | 'inherit'
      | 'primary'
      | 'secondary'
      | 'success'
      | 'error'
      | 'info'
    onClick?: () => void
  }
  const headerBtns: MuiButton[] = isOnDuty
    ? [
        { label: '休憩', variant: 'outlined', disableElevation: true },
        { label: '休息', variant: 'outlined', disableElevation: true },
        {
          label: '退勤',
          variant: 'contained',
          disableElevation: true,
          onClick: onDutyClicked,
        },
      ]
    : [
        {
          label: '出勤',
          variant: 'contained',
          disableElevation: true,
          color: 'warning',
          onClick: onDutyClicked,
        },
      ];

  return (
    <AppHeaderStyle>
      <Link to="/">
        <img src={LogoSymbol} alt="" />
      </Link>
      <div className="app-header-btns">
        {headerBtns.map(
          ({ label, variant, disableElevation, color, onClick }) => (
            <Button
              key={label}
              className="btn"
              variant={variant}
              disableElevation={disableElevation}
              color={color}
              onClick={onClick}
            >
              {label}
            </Button>
          )
        )}
      </div>
      <Snackbar
        open={snackbar.show}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </AppHeaderStyle>
  );
}
