// icons
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
// import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
// import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
// import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import { Snackbar, Button } from '@mui/material';
import { LuggageItem } from 'components/index';
import { useState, useEffect } from 'react';
import { nowString } from 'utils/datetime';
import { Order } from 'utils/interface';

// styles
import 'styles/OrderItem.scss';

type OrderItemProps = {
  order: Order;
  openCancelForm: (n: number) => void;
};

export function OrderItem(props: OrderItemProps) {
  const { order, openCancelForm } = props;
  const {
    id,
    // plan_time,
    // order_type,
    destination,
    address,
    // luggage_type,
    luggages
  } = order;

  const [showDetail, setShowDetail] = useState(false);
  const toggleDetail = () => {
    setShowDetail((v) => !v);
  };

  const cancelOrder = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    openCancelForm(id);
  };
  const [isInProgress, setIsInProgress] = useState(false);
  const [startTime, setStartTime] = useState('');
  const startOrder = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setIsInProgress(true);
    setStartTime(() => nowString());
    setSnackbar({
      show: true,
      // message: `${nowString()} ${
      //   order_type === 'collection' ? 'ピッキング開始' : '配送開始'
      // }`
      message: nowString()
    });
    console.log('startOrder', nowString());
  };

  const [isFinished, setIsFinished] = useState(false);
  const [finishTime, setFinishTime] = useState('');
  const finishOrder = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setIsFinished(true);
    setFinishTime(() => nowString());
    setShowDetail(false);
    setSnackbar({
      show: true,
      // message: `${nowString()} ${
      //   order_type === 'collection' ? '集荷完了' : '納品完了'
      // }`
      message: nowString()
    });
    console.log('finishOrder', nowString());
  };

  const [snackbar, setSnackbar] = useState({ show: false, message: '' });

  useEffect(() => {
    if (snackbar.show) {
      setTimeout(() => {
        setSnackbar({ show: false, message: '' });
      }, 3000);
    }
  }, [snackbar.show]);

  // const labelClass = isFinished
  //   ? 'type finished'
  //   : order_type === 'collection'
  //   ? 'type green'
  //   : order_type === 'delivery'
  //   ? 'type blue'
  //   : 'type';

  return (
    // <div
    //   className={isFinished ? 'order-item finished' : 'order-item'}
    //   onClick={toggleDetail}
    // >
    <div>
      <div className="left">
        <div className="check">
          {/* {isFinished ? (
            <CheckCircleRoundedIcon />
          ) : isInProgress ? (
            <RadioButtonCheckedOutlinedIcon />
          ) : (
            <RadioButtonUncheckedRoundedIcon />
          )} */}
        </div>
      </div>
      <div className="right">
        {isInProgress && !isFinished && (
          <p className="work-in-progress">
            <AccessTimeRoundedIcon />
            {startTime}
            ~
            <span>
              {/* {order_type === 'collection' ? 'ピッキング中' : '配送中'} */}
            </span>
          </p>
        )}
        <div className="header">
          {/* <p className={labelClass}> */}
          <p>
            {isFinished && <span>{finishTime}</span>}
            {/* {order_type === 'collection' ? '集荷' : '納品'} */}
            {isFinished && '完了'}
            {/* <span>{luggages.length}件</span> */}
          </p>
          <p className="plan-time">
            <AccessTimeRoundedIcon />
            <span className={isFinished ? 'outtime' : ''}>
              {/* {plan_time.slice(0, 5)} */}
            </span>
          </p>
        </div>
        {(showDetail || !isFinished) && (
          <div className="col">
            <p className="destination">
              <ApartmentRoundedIcon />
              {destination}
            </p>
            <p className="address">
              <PlaceRoundedIcon />
              {address}
            </p>
            <p className="luggage-type">
              <Inventory2RoundedIcon />
              {/* {luggage_type} */}
            </p>
          </div>
        )}
        {showDetail && (
          <>
            <ol className="luggage-list">
              {luggages.map((luggage) => (
                <LuggageItem key={luggage.id} luggage={luggage} />
              ))}
            </ol>
            {!isFinished && (
              <div className="action-btns">
                <Button
                  className="btn"
                  variant="outlined"
                  disableElevation
                  color="warning"
                  onClick={cancelOrder}
                >
                  キャンセル申請
                </Button>
                <Button
                  // className={
                  //   order_type === 'collection' ? 'btn green' : 'btn blue'
                  // }
                  variant="contained"
                  disableElevation
                  disabled={isInProgress}
                  onClick={startOrder}
                >
                  {/* {order_type === 'collection' ? 'ピッキング' : '配送開始'} */}
                </Button>
                <Button
                  // className={
                  //   order_type === 'collection' ? 'btn green' : 'btn blue'
                  // }
                  variant="contained"
                  disableElevation
                  onClick={finishOrder}
                >
                  {/* {order_type === 'collection' ? '集荷完了' : '配送完了'} */}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <Snackbar
        open={snackbar.show}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  );
}
