import { ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    white?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}

export const LogTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#0d2f59'
      // light: '#ff0000',
      // dark: '#0d131d',
    },
    secondary: {
      main: '#0093ff'
      // light: '#ff0000',
      // dark: '#0d131d',
    },
    warning: {
      main: '#F5545F'
      // light: '#ff0000',
      // dark: '#0d131d',
    },
    success: {
      main: '#87ba12'
      // light: '#ff0000',
      // dark: '#0d131d',
    },
    info: {
      main: '#65aadd'
      // light: '#ff0000',
      // dark: '#0d131d',
    },
    white: {
      main: '#ffffff'
      // light: '#ff0000',
      // dark: '#0d131d',
    }
  },
  typography: {
    fontFamily: [
      '"M PLUS 1p"',
      'Montserrat',
      '"Helvetica"',
      'Arial',
      'sans-serif'
    ].join(',')
  }
};
