// utils
import {
  NotificationItem,
  LabelEnd,
  NoItem,
  PageTitle
} from 'components/index';
import { notifications } from 'dummy/notifications';
import { PageContentStyle } from 'styles/valiables';
import { string2YMD } from 'utils/datetime';
import 'styles/Notification.scss';

export function Notification() {
  const displayItems = () => {
    const origin = notifications;
    const output = [];
    for (let i = 0; i < origin.length; i++) {
      const item = origin[i];
      const day = item.datetime;
      const targetIndex = output.findIndex(
        (outputItem) => outputItem.day === day.slice(0, 10)
      );
      if (targetIndex > -1) {
        output[targetIndex].notifications.push(item);
      } else {
        output.push({
          day: day.slice(0, 10),
          notifications: [item]
        });
      }
    }
    return output;
  };

  return (
    <PageContentStyle className="page-notification">
      <PageTitle type="notifications" />
      {displayItems().length === 0 && <NoItem type="notification" />}
      {displayItems().length > 0 && (
        <>
          <ul>
            {displayItems().map((day) => (
              <li key={day.day}>
                <p className="date">{string2YMD(day.day)}</p>
                <ul>
                  {day.notifications.map((notification) => (
                    <NotificationItem
                      item={notification}
                      key={notification.id}
                    />
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <LabelEnd text="以上" />
        </>
      )}
      {/* <p>{JSON.stringify(notifications)}</p>
      <hr />
      <p>{JSON.stringify(displayItems())}</p> */}
    </PageContentStyle>
  );
}
