import { PositionEntity } from 'entities/Position.entity';

export const dateString = (input: string): string => input.replace(/-/g, '/').replace(/T/g, ' ');

export const nowString = (hasDate = false, now: Date = new Date()): string => {
  const yyyy = now.getFullYear();
  const MM = `0${now.getMonth() + 1}`.slice(-2);
  const dd = `0${now.getDate()}`.slice(-2);
  const hh = `0${now.getHours()}`.slice(-2);
  const mm = `0${now.getMinutes()}`.slice(-2);
  const ss = `0${now.getSeconds()}`.slice(-2);
  return hasDate ? `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}` : `${hh}:${mm}`;
};

export const weekdayString = (date: Date) => {
  const weekdays = ['日', '月', '火', '水', '木', '金', '土', '日'];
  const weekday = date.getDay();
  return weekdays[weekday];
};

export const string2YMD = (input: string) => {
  const dateObj = new Date(dateString(input));
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();
  const weekday = weekdayString(dateObj);

  return `${year}年${month}月${date}日(${weekday})`;
};

/**
 * Date → ○月○日(曜日)
 * @param dateObj
 * @returns
 */
export const displayDate = (dateObj: Date) => {
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();
  const day = weekdayString(dateObj);
  return `${month}月${date}日(${day})`;
};

/**
 * PositionItem 可能時間帯と予定到着時間表示
 * @param startAt
 * @param endAt
 * @param planedArrivalAt
 * @returns
 */
export const positionDuration = (startAt: string, endAt: string, planedArrivalAt?: string) => {
  const startDate = new Date(dateString(startAt));
  const startMonth = startDate.getMonth() + 1;
  const startDay = startDate.getDate();
  const startHour = `0${startDate.getHours()}`.slice(-2);
  const startMinute = `0${startDate.getMinutes()}`.slice(-2);

  const endDate = new Date(dateString(endAt));
  const endMonth = endDate.getMonth() + 1;
  const endDay = endDate.getDate();
  const endHour = `0${endDate.getHours()}`.slice(-2);
  const endMinute = `0${endDate.getMinutes()}`.slice(-2);

  const planDate = planedArrivalAt ? new Date(dateString(planedArrivalAt)) : new Date();
  const planMonth = planDate.getMonth() + 1;
  const planDay = planDate.getDate();
  const planHour = `0${planDate.getHours()}`.slice(-2);
  const planMinute = `0${planDate.getMinutes()}`.slice(-2);

  let duration = '';
  if (startMonth === endMonth && startDay === endDay) {
    duration = `指定: ${startMonth}月${startDay}日 ${startHour}:${startMinute}-${endHour}:${endMinute}`;
  } else if (startMonth === endMonth && startDay !== endDay) {
    duration = `指定: ${startMonth}月${startDay}日 ${startHour}:${startMinute} - ${endDay}日 ${endHour}:${endMinute}`;
  } else {
    duration = `指定: ${startMonth}月${startDay}日 ${startHour}:${startMinute} - ${endMonth}月${endDay}日 ${endHour}:${endMinute}`;
  }
  let plan = '';
  if (startMonth === planMonth && startDay === planDay) {
    plan = `(到着予定${planHour}:${planMinute})`;
  } else if (startMonth === planMonth && startDay !== planDay) {
    plan = `(到着予定 ${planDay}日${planHour}:${planMinute})`;
  } else {
    plan = `(到着予定 ${planMonth}月${planDay}日${planHour}:${planMinute})`;
  }
  return { duration, plan };
};

/**
 * positons内完了している数を数える
 * @param positons
 * @returns
 */
export const countDone = (positons: PositionEntity[]) => {
  let count = 0;
  for (let i = 0; i < positons.length; i++) {
    const { histories } = positons[i];
    const { status } = [...histories].slice(-1)[0] ? [...histories].slice(-1)[0] : { status: '' };
    if (status === 'キャンセル' || status === '積込完了' || status === '配送完了') count++;
  }
  return count;
};
