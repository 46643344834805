import './styles/App.scss';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  AppLayout,
  PublicLayout,
  AuthenticatedLayout,
  Login,
  Home,
  Notification,
  OrderList,
  NotFound,
  PositionList
} from 'components/index';
import { Route, Routes } from 'react-router-dom';
import { LogTheme } from 'styles/log-theme';

function App() {
  const theme = createTheme(LogTheme);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route>
          <Route path="/" element={<PositionList />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<NotFound />} />
        </Route>
        <Route path="/public" element={<PublicLayout />}>
          <Route path=":uuid" element={<OrderList />} />
        </Route>
        <Route path="/auth" element={<AuthenticatedLayout />}>
          <Route index element={<Home />} />
          <Route path="notification" element={<Notification />} />
          <Route path="order" element={<OrderList />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
