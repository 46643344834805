import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import { SwipeableDrawer, IconButton, Button } from '@mui/material';
import { AppLayout, CalendarHeader, PositionItem, LabelEnd, CancelForm, PositionDetail, PositionAction } from 'components/index';
import { initialPositions } from 'dummy/positions';
import { PositionEntity } from 'entities/Position.entity';
import { useState, useEffect } from 'react';
import 'styles/PositionList.scss';
import { displayDate, countDone, nowString } from 'utils/datetime';
import { hasPositionInProgress } from 'utils/status';
import { ActionBtn } from 'utils/types';

export function PositionList() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [positions, setPositions] = useState(initialPositions);
  const doneCount = countDone(positions);

  const [isActionVisible, setIsActionVisible] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);

  const toggleDrawerAction = (open: boolean, position?: PositionEntity) => (event: React.KeyboardEvent | React.MouseEvent) => {
    // console.warn('toggleDrawerAction', open, position);
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) return;
    setIsActionVisible(open);
    if (!currentPosition.alreadyRead) {
      console.log('既読処理');
    }
    if (position) setCurrentPosition(position);
    if (isCancelFormVisible) setIsCancelFormVisible(false);
  };

  const closeDrawerDetail = () => {
    if (isCancelFormVisible) setIsCancelFormVisible(false);
    if (isActionVisible) setIsActionVisible(false);
    setIsDetailVisible(!isDetailVisible);
  };
  const showDrawerDetail = (position?: PositionEntity) => {
    console.log('showDrawerDetail');
    if (position) setCurrentPosition(position);
    if (isCancelFormVisible) setIsDetailVisible(false);
    if (isActionVisible) setIsActionVisible(false);
    if (isConflictVisible) setIsConflictVisible(false);
    setIsDetailVisible(!isDetailVisible);
  };

  const [currentPosition, setCurrentPosition] = useState(positions[0]);

  const actionBtn = (): ActionBtn => {
    const type = currentPosition.unloadCount !== 0 ? 'unload' : 'load';
    const { idx } = currentPosition;
    const { status } = [...currentPosition.histories].slice(-1)[0];
    let text = '';
    const disable = status === '待機開始';

    // 未対応: キャンセル, 積込完了, 配送完了
    if (status === '未配送') {
      text = type === 'load' ? '作業を開始する' : '配送を開始する';
    } else if (status === '作業開始') {
      text = '積み込み済み';
    } else if (['配送開始', '待機開始', '待機完了'].includes(status)) {
      text = '配送済み';
    }

    const inProgressPosition = initialInProgressPosition.position;
    return { idx, type, text, status, disable, inProgressPosition };
  };

  const openCancelForm = () => {
    console.log('openCancelForm');
    if (isDetailVisible) {
      setIsDetailVisible(false);
      setIsActionVisible(true);
      setIsConflictVisible(false);
    }
    setIsCancelFormVisible(true);
  };

  const toggleWaiting = (isWaiting: boolean) => {
    console.log('toggleWaiting');
    const tempPosition = { ...currentPosition };
    const updatedAt = nowString(true);
    if (isWaiting) {
      tempPosition.histories.push({ status: '待機開始', updatedAt });
    } else {
      tempPosition.histories.push({ status: '待機完了', updatedAt });
    }
    setCurrentPosition(tempPosition);
  };

  const [isCancelFormVisible, setIsCancelFormVisible] = useState(false);
  const hideCancelForm = () => {
    setIsCancelFormVisible(false);
  };

  const initialInProgressPosition = hasPositionInProgress(positions);
  const [inProgressPosition, setInProgressPosition] = useState(initialInProgressPosition.position);

  useEffect(() => {
    const updatedInProgressPosition = hasPositionInProgress(positions);
    setInProgressPosition(updatedInProgressPosition.position);
  }, [positions]);

  const [isConflictVisible, setIsConflictVisible] = useState(false);
  const closeDrawerConflict = () => {
    if (isCancelFormVisible) setIsCancelFormVisible(false);
    if (isActionVisible) setIsActionVisible(false);
    setIsConflictVisible(!isConflictVisible);
  };
  const showDrawerConflict = () => {
    console.log('showDrawerConflict');
    if (isCancelFormVisible) setIsCancelFormVisible(false);
    if (isActionVisible) setIsActionVisible(false);
    setIsConflictVisible(!isConflictVisible);
  };

  const redoPositionStatus = () => {
    console.log('redoPositionStatus');
  };
  return (
    <AppLayout header={<CalendarHeader currentDate={currentDate} setCurrentDate={setCurrentDate} />} currentDate={currentDate} setCurrentDate={setCurrentDate}>
      <div className="position-list">
        <div className="display-date-label">{`${displayDate(currentDate)}配送指示 ${doneCount}/${positions.length}件完了`}</div>
        {positions.map((position: PositionEntity, index) => (
          <PositionItem position={position} index={index} key={position.idx} onClick={toggleDrawerAction} showDrawerDetail={() => showDrawerDetail(position)} />
        ))}
        <LabelEnd text={`${doneCount}/${positions.length}件完了`} />
      </div>
      <SwipeableDrawer
        className="position-action-drawer"
        anchor="bottom"
        open={isActionVisible}
        onClose={toggleDrawerAction(false)}
        onOpen={toggleDrawerAction(true)}
      >
        <div className="position-item-wrapper">
          <PositionItem position={currentPosition} onClick={toggleDrawerAction} />
          <IconButton className="detail-btn" onClick={() => showDrawerDetail()}>
            <KeyboardArrowRightIcon />
            <span className="icon-button-text">詳細</span>
          </IconButton>
        </div>
        {isCancelFormVisible && <CancelForm hideCancelForm={hideCancelForm} />}
        <div>
          {!isCancelFormVisible && (
            <PositionAction openCancelForm={openCancelForm} actionBtn={actionBtn} toggleWaiting={toggleWaiting} showDrawerConflict={showDrawerConflict} />
          )}
        </div>
      </SwipeableDrawer>
      <SwipeableDrawer className="position-detail-drawer" anchor="bottom" open={isDetailVisible} onClose={closeDrawerDetail} onOpen={() => showDrawerDetail()}>
        <PositionDetail
          position={currentPosition}
          closeDrawerDetail={closeDrawerDetail}
          openCancelForm={openCancelForm}
          actionBtn={actionBtn}
          toggleWaiting={toggleWaiting}
        />
      </SwipeableDrawer>
      <SwipeableDrawer
        className="position-inprogress-drawer"
        anchor="bottom"
        open={isConflictVisible}
        onClose={closeDrawerConflict}
        onOpen={showDrawerConflict}
      >
        <p className="position-inprogress-title">下記荷物は対応中のようです</p>
        {inProgressPosition && (
          <div className="position-item-wrapper">
            <PositionItem position={inProgressPosition} onClick={toggleDrawerAction} />
            <IconButton className="detail-btn" onClick={() => showDrawerDetail()}>
              <KeyboardArrowRightIcon />
              <span className="icon-button-text">詳細</span>
            </IconButton>
          </div>
        )}
        <div className="position-action-btns">
          <IconButton className="cancel-btn" color="warning" onClick={openCancelForm}>
            <ReportProblemIcon />
          </IconButton>
          <Button className="swipe-btn -redo" variant="contained" disableElevation fullWidth startIcon={<SwipeRightIcon />} onClick={redoPositionStatus}>
            未着手に戻す
          </Button>
        </div>
      </SwipeableDrawer>
    </AppLayout>
  );
}
