// import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import styled from 'styled-components';
import { c } from 'styles/valiables';

const BtnReturnStyle = styled.div`
  margin-bottom: 20px;
  margin-top: -10px;
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: ${c.white}; */
    color: ${c.grey};
    width: 80px;
    padding: 5px 10px 5px 0;
    margin-left: -10px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;
type Props = {
  onClick: () => (event: React.KeyboardEvent | React.MouseEvent) => void;
};

export function BtnReturn({ onClick }: Props) {
  const btnClick = () => {
    console.log('return btn');
    onClick();
  };
  return (
    <BtnReturnStyle className="return-btn">
      {/* <div role="button" onClick={btnClick} tabIndex={0}>
        <ChevronLeftOutlinedIcon />
        戻る
      </div> */}
    </BtnReturnStyle>
  );
}
