import {
  OrderItem,
  LabelEnd,
  PageTitle,
  NoItem,
  CancelModal
} from 'components/index';
import { orders } from 'dummy/orders';
import { useState, useEffect } from 'react';
import 'styles/InstructionList.scss';
import { PageContentStyle } from 'styles/valiables';
import { dateString } from 'utils/datetime';
import { Order } from 'utils/interface';

export function OrderList() {
  const today = '2023-02-13';
  const todayDate = new Date(dateString(today));

  const ordersToday = () => orders.filter((order) => order.order_date === today);
  const ordersUnread = () => orders.filter((order) => !order.is_read);
  const ordersPast = () => orders.filter((order) => {
      const targetDate = new Date(dateString(order.order_date));
      return targetDate < todayDate;
    });
  type orderFilterType = 'today' | 'unread' | 'past';
  const [orderFilter, setInstructionFilter] = useState(
    'today' as orderFilterType
  );

  let orderDisplay: Order[] = [];
  if (orderFilter === 'today') {
    orderDisplay = ordersToday();
  } else if (orderFilter === 'unread') {
    orderDisplay = ordersUnread();
  } else {
    orderDisplay = ordersPast();
  }

  // current order (actions: start, finish, cancel)
  const [currentOrderId, setCurrentOrderId] = useState(101);
  const currentOrder = orders.find((order) => order.id === currentOrderId) || orders[0];

  // cancel form
  const [showCancelForm, setShowCancelForm] = useState(false);
  const openCancelForm = (id: number) => {
    setCurrentOrderId(id);
    setShowCancelForm(true);
  };
  const closeCancelForm = () => {
    setIsSlideOut(true);
    setTimeout(() => {
      setShowCancelForm(false);
    }, 300);
  };

  // animation for cancel form
  const [isSlideOut, setIsSlideOut] = useState(false);
  useEffect(() => {
    if (!showCancelForm) {
      setIsSlideOut(false);
    }
  }, [showCancelForm]);

  return (
    <PageContentStyle className="page-instruction-list">
      <PageTitle type="orders" />
      <div className="instruction-type-tabs">
        {/* <div
          className={`tab ${orderFilter === 'past' ? 'active' : ''}`}
          onClick={() => setInstructionFilter('past')}
        >
          完了
        </div>
        <div
          className={`tab ${orderFilter === 'today' ? 'active' : ''}`}
          onClick={() => setInstructionFilter('today')}
        >
          対応中
        </div>
        <div
          className={`tab ${orderFilter === 'unread' ? 'active' : ''}`}
          onClick={() => setInstructionFilter('unread')}
        >
          未読
        </div> */}
      </div>
      {orderDisplay.length === 0 ? (
        <NoItem type={orderFilter} />
      ) : (
        <>
          <ul>
            {orderDisplay.map((order) => (
              <OrderItem
                key={order.id}
                order={order}
                openCancelForm={openCancelForm}
              />
            ))}
          </ul>
          <LabelEnd text={`以上 全${orderDisplay.length}件`} />
        </>
      )}
      {/* <p>{JSON.stringify(instructions)}</p> */}
      {showCancelForm && (
        <CancelModal
          slideOut={isSlideOut}
          order={currentOrder}
          closeCancelForm={closeCancelForm}
        />
      )}
    </PageContentStyle>
  );
}
