import { PositionEntity } from 'entities/Position.entity';

export const initialPositions: PositionEntity[] = [
  {
    idx: '3000',
    name: '東松山センター',
    address: '',
    loadCount: 3,
    unloadCount: 0,
    startAt: '2023-04-05 09:00:00',
    endAt: '2023-05-06 11:00:00',
    planedArrivalAt: '2023-05-05 09:00:00',
    alreadyRead: true,
    histories: [
      {
        status: '積込完了',
        updatedAt: '2023-04-05 09:12:12'
      }
    ],
    operations: []
  },
  {
    idx: '3001',
    name: 'イント藤原営業所',
    address: '埼玉県東松山市本町1丁目4-8',
    loadCount: 0,
    unloadCount: 2,
    startAt: '2023-04-05 09:00:00',
    endAt: '2023-04-05 11:00:00',
    planedArrivalAt: '2023-04-05 09:00:00',
    alreadyRead: true,
    histories: [
      {
        status: '未配送',
        updatedAt: '2023-04-05 06:12:12'
      },
      {
        status: '配送開始',
        updatedAt: '2023-04-05 09:12:12'
      }
    ],
    operations: []
  },
  {
    idx: '3002',
    name: '埼玉松山営業所',
    address: '埼玉県東松山市本町1丁目4-8',
    loadCount: 0,
    unloadCount: 1,
    startAt: '2023-04-05 07:00:00',
    endAt: '2023-04-05 11:00:00',
    planedArrivalAt: '2023-04-05 09:30:00',
    alreadyRead: true,
    histories: [
      {
        status: '未配送',
        updatedAt: '2023-04-05 06:12:12'
      }
    ],
    operations: []
  },
  {
    idx: '3003',
    name: '㈱日本蓮田物流センター',
    address: '埼玉県東松山市本町1丁目4-8',
    loadCount: 12,
    unloadCount: 1,
    startAt: '2023-04-05 09:00:00',
    endAt: '2023-04-05 15:00:00',
    planedArrivalAt: '2023-04-05 10:30:00',
    alreadyRead: true,
    histories: [
      {
        status: '未配送',
        updatedAt: '2023-04-05 06:12:12'
      }
    ],
    operations: []
  },
  {
    idx: '3004',
    name: '埼玉物流センター',
    address: '埼玉県東松山市本町1丁目4-8',
    loadCount: 6,
    unloadCount: 0,
    startAt: '2023-04-05 12:00:00',
    endAt: '2023-04-05 20:00:00',
    planedArrivalAt: '2023-04-05 12:30:00',
    alreadyRead: true,
    histories: [
      {
        status: '未配送',
        updatedAt: '2023-04-05 06:12:12'
      }
    ],
    operations: []
  },
  {
    idx: '3005',
    name: '東松山本町営業所',
    address: '埼玉県東松山市本町1丁目4-8',
    loadCount: 5,
    unloadCount: 0,
    startAt: '2023-04-05 12:00:00',
    endAt: '2023-04-05 20:00:00',
    planedArrivalAt: '2023-04-05 12:30:00',
    alreadyRead: true,
    histories: [
      {
        status: 'キャンセル',
        updatedAt: '2023-04-05 06:12:12'
      }
    ],
    operations: []
  },
  {
    idx: '3006',
    name: '東松山本町営業所',
    address: '埼玉県東松山市本町1丁目4-8',
    loadCount: 0,
    unloadCount: 7,
    startAt: '2023-04-05 09:00:00',
    endAt: '2023-04-05 20:00:00',
    planedArrivalAt: '2023-04-05 13:33:00',
    alreadyRead: false,
    histories: [
      {
        status: '未配送',
        updatedAt: '2023-04-05 06:12:12'
      }
    ],
    operations: []
  }
];
