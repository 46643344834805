import { PositionEntity } from 'entities/Position.entity';

export const hasPositionInProgress = (positions: PositionEntity[]) => {
  const inProgressStatus = ['作業開始', '配送開始', '待機開始', '待機完了'];
  for (let i = 0; i < positions.length; i++) {
    const position = positions[i];
    const { histories } = position;
    const latest = histories.slice(-1)[0];
    if (inProgressStatus.includes(latest?.status)) return { has: true, position };
  }
  return { has: false, position: undefined };
};
