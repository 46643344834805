import styled from 'styled-components';
import { c } from 'styles/valiables';

const LabelUnreadStyle = styled.span`
  background-color: ${c.warning};
  padding: 1px 4px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 0.6rem;
  color: #ffffff;
`;
export function LabelUnread() {
  return <LabelUnreadStyle>未読</LabelUnreadStyle>;
}
