import { Outlet } from 'react-router-dom';

import 'styles/AppLayout.scss';

type AppLayoutProps = {
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  currentDate?: Date;
  setCurrentDate?: (date: Date) => void;
};
export function AppLayout(props: AppLayoutProps) {
  const { header, children, footer, currentDate, setCurrentDate } = props;
  return (
    <div className="app-layout">
      <div className="app-layout-content">
        {header}
        <div className="app-content">
          <Outlet />
          {children}
        </div>
        {footer}
      </div>
    </div>
  );
}
