import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { IconNotification } from 'components/index';
import { useNavigate } from 'react-router-dom';

type Props = {
  // title: string;
  // order_date: string;
  // plan_time: string;
};
export function OrderItemHome(props: Props) {
  const navigate = useNavigate();
  const showDetail = () => {
    navigate('/auth/order');
  };

  // const { title, order_date, plan_time } = props;

  return (
    // <li className="notification-item unread" onClick={showDetail}>
    <li>
      <IconNotification type="order_new" />
      <p className="title">
        <span className="unread">未読</span>
        {/* {title} */}
      </p>
      <p className="datetime">
        <AccessTimeRoundedIcon />
        {/* {order_date} */}
        {/* {plan_time} */}
      </p>
    </li>
  );
}
