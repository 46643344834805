import styled from 'styled-components';

export const c = {

  white: '#ffffff',
  black: '#000000',
  smoke: '#f0f0f0',

  dark: '#223354',
  dark10: '#E9EBEE',
  dark50: '#9099A9',

  grey: '#6e7781',
  grey20: '#e2e4e6',
  grey40: '#c5c9cd',
  grey150: '#373b40',

  blue: '#65aadd',
  blue10: '#F0F6FC',
  blueDark: '#2290e5',

  green: '#aacf52',
  greenDark: '#87ba12',

  yellow: '#f9c158',
  yellowDark: '#ffa600',

  vivid: '#00e0ff',
  lightblue: '#0093ff',
  navy: '#0d2f59',

  bgLight: '#151f32',
  bgDark: '#0d131d',

  primary: '#0d2f59',
  secondary: '#0093ff',
  warning: '#f5545f',
  warning10: '#FEEEEF',

  info: '#65aadd',
  success: '#87ba12',
  success10: '#F3F8E7',

  headerHeight: '70px',
};

export const PageContentStyle = styled.div`
  padding: 20px 20px 50px 20px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const H2Style = styled.div`
  font-size: 1.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 30px 0 10px 0;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${c.grey};
  }
`;
