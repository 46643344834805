import { NoItem } from 'components/index';
import { useLocation, Link } from 'react-router-dom';

export function NotFoundTemplate() {
  const location = useLocation();
  return (
    <>
      <NoItem type="not_found" />
      <h1
        style={{
          textAlign: 'center',
          marginBottom: '20px',
          fontSize: '1.2rem',
        }}
      >
        {location.pathname}
      </h1>
      <Link
        to="/"
        style={{ textAlign: 'center', width: '100%', display: 'block' }}
      >
        TOPに戻る
      </Link>
    </>
  );
}
