import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { Button } from '@mui/material';
import { PositionItem, OperationItem, PositionAction } from 'components/index';
import { operations } from 'dummy/operations';
import { PositionEntity } from 'entities/Position.entity';
import 'styles/PositionDetail.scss';
import { ActionBtn } from 'utils/types';

type Props = {
  position: PositionEntity;
  closeDrawerDetail: () => void;
  openCancelForm: () => void;
  actionBtn: () => ActionBtn;
  toggleWaiting: (isWaiting: boolean) => void;
};
export function PositionDetail({ closeDrawerDetail, position, openCancelForm, actionBtn, toggleWaiting }: Props) {
  const { name, loadCount, unloadCount, address, startAt, endAt, planedArrivalAt, alreadyRead, histories } = position;
  const latest = histories.slice(-1)[0];

  const isFinished = ['キャンセル', '積込完了', '配送完了'].includes(latest?.status);

  return (
    <div className={`position-detail ${isFinished ? '-is-finished' : ''}`}>
      <div className="position-detail-header">
        <Button onClick={closeDrawerDetail}>
          <ChevronLeftOutlinedIcon />
          戻る
        </Button>
        <PositionItem position={position} />
      </div>
      <ul className="position-detail-list">
        {operations.map((operation) => (
          <OperationItem operation={operation} key={operation.id} />
        ))}
      </ul>
      {!isFinished && (
        <div className="position-detail-footer">
          <PositionAction openCancelForm={openCancelForm} actionBtn={actionBtn} toggleWaiting={toggleWaiting} />
        </div>
      )}
    </div>
  );
}
