import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { IconNotification } from 'components/index';
import { useNavigate } from 'react-router-dom';
// utils
import { Notification } from 'utils/interface';
//
// icons
// styles
import 'styles/NotificationItem.scss';

type Props = {
  item: Notification;
};
export function NotificationItem(props: Props) {
  const { item } = props;
  const {
    // id,
    type,
    datetime
    // is_read,
  } = item;

  const title = () => {
    switch (type) {
      case 'order_new':
        return '新しい配送指示が届きました';
      case 'cancel_approved':
        return 'キャンセル申請が許可されました';
      case 'cancel_forbidden':
        return 'キャンセル申請が却下されました';
      default:
        return 'sth wrong';
    }
  };
  const navigate = useNavigate();

  const showDetail = () => {
    navigate('/auth/order');
  };

  return (
    // <li
    //   className={`notification-item ${is_read ? '' : 'unread'}`}
    //   onClick={showDetail}
    // >
    <li>
      <IconNotification type={type} />
      <p className="title">
        {/* {!is_read && <span className="unread">未読</span>} */}
        {title()}
      </p>
      <p className="datetime">
        <AccessTimeRoundedIcon />
        {datetime.slice(0, 16)}
      </p>
    </li>
  );
}
