// import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
// import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
// import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import styled from 'styled-components';
import { c } from 'styles/valiables';

const IconNotificationStyle = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: -1;
  svg {
    fill: ${c.smoke};
    width: 45px;
    height: 45px;
    vertical-align: bottom;
    &[data-testid='WarningRoundedIcon'] {
      fill: ${c.warning10};
    }
    &[data-testid='VerifiedUserRoundedIcon'] {
      fill: ${c.success10};
    }
    &[data-testid='AssignmentRoundedIcon'] {
      fill: ${c.blue10};
    }
  }
  &.past svg[data-testid='AssignmentRoundedIcon'] {
    fill: ${c.smoke};
  }
  &.future svg[data-testid='AssignmentRoundedIcon'] {
    fill: ${c.success10};
  }
  &.today svg[data-testid='AssignmentRoundedIcon'] {
    fill: ${c.blue10};
  }
`;

type Props = {
  type: 'order_new' | 'cancel_approved' | 'cancel_forbidden'
  color?: 'today' | 'future' | 'past'
}

export function IconNotification(props: Props) {
  const { type, color } = props;
  return (
    <IconNotificationStyle className={color}>
      {/* {type === 'order_new' ? (
        <AssignmentRoundedIcon />
      ) : type === 'cancel_approved' ? (
        <VerifiedUserRoundedIcon />
      ) : type === 'cancel_forbidden' ? (
        <WarningRoundedIcon />
      ) : (
        ''
      )} */}
    </IconNotificationStyle>
  );
}
