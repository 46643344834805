import { Luggage } from 'utils/interface';

import 'styles/LuggageItem.scss';

type LuggageItemProps = {
  luggage: Luggage;
};

export function LuggageItem(props: LuggageItemProps) {
  const { luggage } = props;
  const { id, name, weight } = luggage;
  return (
    <li className="luggage-item">
      <span className="id">{id}</span>
      <span className="name">{name}</span>
      <span className="weight">
        {weight.toLocaleString()}
        kg
      </span>
    </li>
  );
}
