import LoginIcon from '@mui/icons-material/Login';
import { TextField, Button } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import LogoFull from '../assets/svg/logo_full.svg';

import 'styles/Login.scss';

export function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const login = () => {
    console.log('login', username, password);
    navigate('/auth');
  };
  const samplePublic = () => {
    navigate('/public/44de7448-1303-4839-a952-d4379eeb271b');
  };

  return (
    <div className="page-login">
      <button type="button" onClick={samplePublic}>
        <img src={LogoFull} alt="" />
      </button>
      <h1>ログイン</h1>
      <p>ドライバー・マイページ</p>

      <div className="login-input-wrapper">
        <TextField
          label="ユーザー名"
          type="text"
          variant="standard"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="パスワード"
          type="password"
          autoComplete="current-password"
          variant="standard"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          className="login-btn"
          endIcon={<LoginIcon />}
          variant="contained"
          disableElevation
          fullWidth
          size="large"
          onClick={login}
        >
          ログイン
        </Button>
        <Link to="/password_forget">パスワードをお忘れの方 &gt;</Link>
      </div>
      <div className="login-sub-info">
        <Link to="/terms-of-use">利用規約</Link>
        <Link to="/privacy-policy">プライバシー・ポリシー</Link>
      </div>
    </div>
  );
}
