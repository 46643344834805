/* eslint-disable jsx-a11y/click-events-have-key-events */
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import ArchiveIcon from '@mui/icons-material/Archive';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { PositionEntity } from 'entities/Position.entity';
import 'styles/PositionItem.scss';
import { positionDuration } from 'utils/datetime';

type PositionItemProps = {
  position: PositionEntity;
  index?: number;
  onClick?: (open: boolean, position: PositionEntity) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  showDrawerDetail?: () => void;
};

export function PositionItem({ position, index, onClick, showDrawerDetail }: PositionItemProps) {
  const { name, loadCount, unloadCount, address, startAt, endAt, planedArrivalAt, alreadyRead, histories } = position;
  const latest = histories.slice(-1)[0];
  // 待機開始,待機完了未対応
  const isInProgress = ['作業開始', '配送開始', '待機開始', '待機完了'].includes(latest?.status);
  const isFinished = ['キャンセル', '積込完了', '配送完了'].includes(latest?.status);

  const durationDisplay = positionDuration(startAt, endAt, planedArrivalAt);

  if (isFinished) {
    return (
      <div className="position-item -is-finished" role="button" onClick={showDrawerDetail} tabIndex={0}>
        <div className="position-info-row">
          <p>
            <CheckCircleIcon />
            {latest?.status === 'キャンセル' ? 'キャンセル済' : '完了'}
          </p>
          <p>
            {!!loadCount && (
              <>
                <ArchiveIcon />
                <span>{`集荷 ${loadCount}件`}</span>
              </>
            )}
          </p>
          <p>
            {!!unloadCount && (
              <>
                <UnarchiveIcon />
                <span>{`納品 ${unloadCount}件`}</span>
              </>
            )}
          </p>
          <p>
            <ApartmentRoundedIcon />
            {name}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className={`position-item ${isInProgress ? '-in-progress' : ''}`} role="button" onClick={onClick ? onClick(true, position) : undefined} tabIndex={0}>
      {isInProgress && (
        <div className="status-header">
          <AccessTimeRoundedIcon />
          <p className="status-time">{`${latest.updatedAt.slice(11, 16)}~ ${latest.status}`}</p>
        </div>
      )}
      <div className="label-header">
        {!alreadyRead && <span className="label -red">未読</span>}
        {!!loadCount && <span className="label -green">{`集荷 ${loadCount}件`}</span>}
        {!!unloadCount && <span className="label -blue">{`納品 ${unloadCount}件`}</span>}
      </div>
      <span className="index-display">{index}</span>
      <div className="position-info-row">
        <ApartmentRoundedIcon />
        <p>{name}</p>
      </div>
      <div className="position-info-row -address">
        <PlaceRoundedIcon />
        <p>{address}</p>
      </div>
      <div className="position-info-row -address">
        <AccessTimeRoundedIcon />
        <p>
          <span>{durationDisplay.duration}</span>
          <span>{durationDisplay.plan}</span>
        </p>
      </div>
    </div>
  );
}
