import styled from 'styled-components';
import { c } from 'styles/valiables';

type Props = {
  text: string;
};

const LabelEndStyle = styled.p`
  font-size: 0.8rem;
  color: ${c.dark50};
  display: block;
  text-align: center;
  margin: 20px 0 100px;
  span {
    position: relative;
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 50px;
      height: 1px;
      background-color: ${c.dark50};
      top: 50%;
    }
    &:before {
      right: calc(100% + 10px);
    }
    &:after {
      left: calc(100% + 10px);
    }
  }
`;

export function LabelEnd(props: Props) {
  const { text } = props;
  return (
    <LabelEndStyle>
      <span>{text}</span>
    </LabelEndStyle>
  );
}
