import { Notification } from 'utils/interface';

export const notifications: Notification[] = [
  {
    id: 301,
    type: 'cancel_approved',
    datetime: '2023-02-13 13:23:23',
    is_read: false,
    instruction_id: 103,
  },
  {
    id: 302,
    type: 'order_new',
    datetime: '2023-02-13 11:23:23',
    is_read: false,
    instruction_id: 103,
  },
  {
    id: 303,
    type: 'cancel_forbidden',
    datetime: '2023-02-13 07:24:23',
    is_read: true,
    instruction_id: 103,
  },
  {
    id: 304,
    type: 'cancel_approved',
    datetime: '2023-02-13 07:23:23',
    is_read: true,
    instruction_id: 103,
  },
  {
    id: 305,
    type: 'order_new',
    datetime: '2023-02-13 06:23:23',
    is_read: true,
    instruction_id: 103,
  },
  {
    id: 306,
    type: 'order_new',
    datetime: '2023-02-12 13:23:23',
    is_read: true,
    instruction_id: 102,
  },
  {
    id: 307,
    type: 'order_new',
    datetime: '2023-02-12 06:23:23',
    is_read: true,
    instruction_id: 102,
  },
  {
    id: 308,
    type: 'order_new',
    datetime: '2023-02-11 13:23:23',
    is_read: true,
    instruction_id: 101,
  },
  {
    id: 309,
    type: 'cancel_approved',
    datetime: '2023-02-10 13:23:23',
    is_read: true,
    instruction_id: 100,
  },
];
