import { OperationEntity } from 'entities/Operation.entity';

export const operations: OperationEntity[] = [
  {
    id: 10001,
    code: '10001',
    shipperName: '○△□商事',
    positionName: '〇〇営業所',
    positionAddress: '埼玉県松山市本町1-4-8 3F',
    startAt: '2023-04-05 09:00:00',
    endAt: '2023-05-06 11:00:00',
    itemName: '飲料',
    itemCount: 10,
    itemWeightG: 123,
    itemVolumeMm3: 234,
    memo: '備考備考〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇備考備考〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇備考備考〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇'
  },
  {
    id: 10002,
    code: '10002',
    shipperName: '○△□商事',
    positionName: '2F  〇〇スーパー売り場',
    positionAddress: '埼玉県松山市本町1-4-8 3F',
    startAt: '2023-04-05 09:00:00',
    endAt: '2023-05-06 11:00:00',
    itemCount: 10,
    itemWeightG: 123,
    itemVolumeMm3: 234,
    memo: '備考備考'
  },
  {
    id: 10003,
    code: '10003',
    shipperName: '○△□商事',
    positionName: '3F 〇〇売り場',
    positionAddress: '埼玉県松山市本町1-4-8 3F',
    startAt: '2023-04-05 09:00:00',
    endAt: '2023-05-06 11:00:00',
    itemName: '飲料',
    itemCount: 10,
    itemWeightG: 123
  },
  {
    id: 10004,
    code: '10004',
    shipperName: '○△□商事',
    positionName: '3F 〇〇売り場',
    positionAddress: '埼玉県松山市本町1-4-8 3F',
    startAt: '2023-04-05 09:00:00',
    endAt: '2023-05-06 11:00:00',
    itemName: '飲料',
    itemCount: 10,
    itemWeightG: 123,
    itemVolumeMm3: 234,
    memo: '備考備考'
  }
];
