import { Order } from 'utils/interface';

export const orders: Order[] = [
  {
    id: 333,
    order_date: '2023-02-11',
    notification_datetime: '2023-02-10 17:28:12',
    is_read: true,
    started_at: '2023-02-10 17:28:12',
    finished_at: null,
    plan_time: '05:20:00',
    order_type: 'collection',
    destination: '東松山センター',
    address: '埼玉県東松山市本町1丁目4-8',
    luggage_type: 'サンプル飲料',
    luggages: [
      { id: 'a18', name: '菓子', weight: 1050 },
      { id: 'a2', name: '菓子', weight: 2844 },
      { id: 'a1', name: '菓子', weight: 1680 },
    ],
  },
  {
    id: 334,
    order_date: '2023-02-13',
    notification_datetime: '2023-02-10 17:28:12',
    is_read: true,
    started_at: '2023-02-10 17:28:12',
    finished_at: null,
    plan_time: '06:10:00',
    order_type: 'delivery',
    destination: 'イント藤原営業所',
    address: '埼玉県東松山市本町1丁目4-8',
    luggage_type: 'サンプル飲料',
    luggages: [
      {
        id: 'a50',
        name: '飲料',
        weight: 244,
      },
    ],
  },
  {
    id: 335,
    order_date: '2023-02-13',
    notification_datetime: '2023-02-10 17:28:12',
    is_read: false,
    started_at: '2023-02-10 17:28:12',
    finished_at: null,
    plan_time: '06:56:00',
    order_type: 'collection',
    destination: '㈱日本蓮田物流センター',
    address: '埼玉県蓮田市城637-1',
    luggage_type: 'サンプル飲料',
    luggages: [
      {
        id: 'a56',
        name: '飲料',
        weight: 1155,
      },
      {
        id: 'a59',
        name: '飲料',
        weight: 71,
      },
    ],
  },
];
