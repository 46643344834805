import EventRoundedIcon from '@mui/icons-material/EventRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Button, IconButton, Snackbar } from '@mui/material';
import LogoSymbolWhite from 'assets/svg/logo_symbol_white.svg';
import { userInfo } from 'dummy/user';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'styles/CalendarHeader.scss';
import { nowString, weekdayString } from 'utils/datetime';
import { UserStatusValueObject } from 'valueObjects/UserStatus.valueObject';

type CalendarHeaderProps = {
  currentDate: Date;
  setCurrentDate: (date: Date) => void;
};

export function CalendarHeader({ currentDate, setCurrentDate }: CalendarHeaderProps) {
  const [userStatus, setUserStatus] = useState(userInfo.status);
  const [currentMonthTitle, setCurrentMonthTitle] = useState('');
  const [currentWeekCenterDate, setCurrentWeekCenterDate] = useState(currentDate);
  const [dates, setDates] = useState<{ display: string; date: Date; weekday: string }[]>([]);
  const [snackbar, setSnackbar] = useState({ show: false, message: '' });

  const changeUserStatus = (status: UserStatusValueObject) => {
    setUserStatus(status);
    setSnackbar({ show: true, message: `${nowString()} ${status}` });
  };

  useEffect(() => {
    if (snackbar.show) {
      setTimeout(() => {
        setSnackbar({ show: false, message: '' });
      }, 3000);
    }
  }, [snackbar.show]);

  const setupCurrentWeek = (centerDate: Date) => {
    const localDates = [];
    for (let i = -3; i <= 3; i++) {
      const d = new Date(centerDate.getTime());
      d.setDate(centerDate.getDate() + i);
      const month = d.getMonth() + 1;
      const day = d.getDate();
      const display = day === 1 ? `${month}/${day}` : `${day}`;
      const weekday = weekdayString(d);
      localDates.push({ display, date: d, weekday });
    }
    setDates(localDates);
  };

  const setupTitle = (centerDate: Date) => {
    const first = new Date(centerDate.getTime());
    const last = new Date(centerDate.getTime());
    first.setDate(centerDate.getDate() - 3);
    last.setDate(centerDate.getDate() + 3);
    const year = first.getFullYear();
    const monthFirst = first.getMonth() + 1;
    const monthLast = last.getMonth() + 1;

    let titleString = '';
    if (monthFirst === monthLast) {
      titleString = `${year}年${monthFirst}月`;
    } else {
      titleString = `${year}年${monthFirst}月 ~ ${monthLast}月`;
    }
    setCurrentMonthTitle(titleString);
  };

  const setupToday = () => {
    setCurrentDate(new Date());
    setCurrentWeekCenterDate(new Date());
  };

  useEffect(() => {
    setupCurrentWeek(currentWeekCenterDate);
    setupTitle(currentWeekCenterDate);
  }, [currentWeekCenterDate]);

  const prevWeek = () => {
    const newDate = new Date(currentWeekCenterDate.getTime());
    newDate.setDate(newDate.getDate() - 7);
    setCurrentWeekCenterDate(newDate);
  };
  const nextWeek = () => {
    const newDate = new Date(currentWeekCenterDate.getTime());
    newDate.setDate(newDate.getDate() + 7);
    setCurrentWeekCenterDate(newDate);
  };
  return (
    <div className="calendar-header">
      <div className="globle-menu">
        <Link to="/">
          <img src={LogoSymbolWhite} alt="" />
        </Link>
        <div className="globle-user-status-btns">
          {userStatus === '勤務中' && (
            <Button className="user-status-btn-rest" variant="outlined" disableElevation onClick={() => changeUserStatus('休憩中')}>
              休憩
            </Button>
          )}
          {userStatus === '休憩中' && (
            <Button className="user-status-btn-rest" variant="outlined" disableElevation onClick={() => changeUserStatus('勤務中')}>
              休憩終了
            </Button>
          )}
          {userStatus !== '勤務終了' && (
            <Button className="user-status-btn-leave" variant="contained" disableElevation onClick={() => changeUserStatus('勤務終了')}>
              退勤
            </Button>
          )}
          {userStatus === '勤務終了' && (
            <Button className="user-status-btn-come" variant="contained" color="warning" disableElevation onClick={() => changeUserStatus('勤務中')}>
              出勤
            </Button>
          )}
        </div>
      </div>
      <div className="calendar-menu">
        <div className="calendar-menu-title">
          <p>{currentMonthTitle}</p>
        </div>
        <div className="calendar-menu-actions">
          <IconButton aria-label="prev" color="white" onClick={prevWeek}>
            <KeyboardArrowLeftRoundedIcon />
          </IconButton>
          <IconButton aria-label="next" color="white" onClick={nextWeek}>
            <KeyboardArrowRightRoundedIcon />
          </IconButton>
          <IconButton aria-label="next" color="white" onClick={setupToday}>
            <EventRoundedIcon />
          </IconButton>
        </div>
      </div>
      <ul className="calendar-paging">
        {dates.map((date) => (
          <Button
            key={date.display}
            className={`calendar-paging-btn ${currentDate.getTime() === date.date.getTime() ? '-is-active' : ''}`}
            variant="outlined"
            color="white"
            sx={{
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              margin: '0 4px'
            }}
            onClick={() => setCurrentDate(date.date)}
          >
            {date.display}
            <span className="sub-weekday">{date.weekday}</span>
          </Button>
        ))}
      </ul>
      <Snackbar open={snackbar.show} message={snackbar.message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />
    </div>
  );
}
