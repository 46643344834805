import { OperationEntity } from 'entities/Operation.entity';
import { positionDuration } from 'utils/datetime';
import 'styles/OperationItem.scss';

type Props = {
  operation: OperationEntity;
};
export function OperationItem({ operation }: Props) {
  const { id, code, shipperName, positionName, positionAddress, startAt, endAt, itemName, itemCount, itemWeightG, itemVolumeMm3, memo } = operation;
  const { duration } = positionDuration(startAt, endAt);
  return (
    <div className="operation-item">
      <div className="row">
        <p className="label">注文番号</p>
        <p className="value">{`No.${code}`}</p>
      </div>
      <div className="row">
        <p className="label">荷主</p>
        <p className="value">{shipperName}</p>
      </div>
      <div className="row">
        <p className="label">作業地</p>
        <p className="value -bold">{positionName}</p>
      </div>
      <div className="row">
        <p className="label">住所</p>
        <p className="value">{positionAddress}</p>
      </div>
      <div className="row">
        <p className="label">指定時間</p>
        <p className="value">{duration}</p>
      </div>
      {itemName && (
        <div className="row">
          <p className="label">商品名</p>
          <p className="value">{itemName}</p>
        </div>
      )}
      <div className="row">
        <p className="label">数量</p>
        <p className="value">{itemCount}</p>
      </div>
      <div className="row">
        <p className="label">重量</p>
        <p className="value">{`${itemWeightG}kg`}</p>
      </div>
      {itemVolumeMm3 && (
        <div className="row">
          <p className="label">体積</p>
          <p className="value">{`${itemVolumeMm3}㎥`}</p>
        </div>
      )}
      {memo && (
        <div className="row">
          <p className="label">備考</p>
          <p className="value">{memo}</p>
        </div>
      )}
    </div>
  );
}
