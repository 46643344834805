import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import styled from 'styled-components';
import { c } from 'styles/valiables';

const PageTitleStyle = styled.h1`
  font-size: 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: ${c.grey};
  }
`;
type Props = {
  type:
    | 'notifications'
    | 'orders'
    | 'home'
    | 'notification_new'
    | 'order_new'
}

export function PageTitle(props: Props) {
  const { type } = props;
  return (
    <PageTitleStyle>
      {type === 'home' && (
        <>
          <HomeRoundedIcon />
          ホーム
        </>
      )}
      {type === 'orders' && (
        <>
          <AssignmentRoundedIcon />
          配送指示一覧
        </>
      )}
      {type === 'notifications' && (
        <>
          <NotificationsActiveRoundedIcon />
          通知一覧
        </>
      )}
      {type === 'notification_new' && (
        <>
          <NotificationsActiveRoundedIcon />
          新着通知
        </>
      )}
      {type === 'order_new' && (
        <>
          <AssignmentRoundedIcon />
          新着配送指示
        </>
      )}
    </PageTitleStyle>
  );
}
