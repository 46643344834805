// utils

// components

// icons
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import { LuggageItem, CancelForm } from 'components/index';
import { useState, useEffect } from 'react';
import { Order } from 'utils/interface';

// styles
import 'styles/CancelModal.scss';

type CancelFormProps = {
  order: Order;
  closeCancelForm: () => void;
  slideOut: boolean;
};
export function CancelModal(props: CancelFormProps) {
  const { order, closeCancelForm, slideOut } = props;
  const {
    // plan_time,
    // order_type,
    destination,
    address,
    // luggage_type,
    luggages
  } = order;

  const labelClassMap = {
    collection: 'type green',
    delivery: 'type blue',
    default: 'type'
  };

  // const labelClass = labelClassMap[order_type] || labelClassMap.default;

  const localCloseCancelForm = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget) return;
    closeCancelForm();
  };

  const [slideIn, setSlideIn] = useState(false);
  useEffect(() => setSlideIn(true), []);
  return (
    // <div
    //   className={`cancel-modal-bg ${slideIn ? 'slideIn' : ''} ${
    //     props.slideOut ? 'slideOut' : ''
    //   }`}
    //   onClick={localCloseCancelForm}
    // >
    <div>
      <div
        className={`cancel-modal ${slideIn ? 'slideIn' : ''} ${
          slideOut ? 'slideOut' : ''
        }`}
      >
        <h2>この案件をキャンセルしますか?</h2>
        <div className="header">
          {/* <p className={labelClass}>
            {order_type === 'collection' ? '集荷' : '納品'}
            <span>{luggages.length}件</span>
          </p> */}
          <p className="plan-time">
            <AccessTimeRoundedIcon />
            {/* <span>{plan_time.slice(0, 5)}</span> */}
          </p>
        </div>
        <div className="col">
          <p className="destination">
            <ApartmentRoundedIcon />
            {destination}
          </p>
          <p className="address">
            <PlaceRoundedIcon />
            {address}
          </p>
          <p className="luggage-type">
            <Inventory2RoundedIcon />
            {/* {luggage_type} */}
          </p>
        </div>
        <ol className="luggage-list">
          {luggages.map((luggage) => (
            <LuggageItem key={luggage.id} luggage={luggage} />
          ))}
        </ol>
        {/* <CancelForm /> */}
      </div>
    </div>
  );
}
