import {
  PageTitle,
  // DriverInfo,
  DividingLine,
  // NotificationItem,
  OrderItemHome
} from 'components/index';
// utils
// import { notifications } from 'dummy/notifications';
import { orders } from 'dummy/orders';
// import { userInfo } from 'dummy/user';
import { PageContentStyle } from 'styles/valiables';

export function Home() {
  // const notificationsNew = () =>
  //   notifications.filter((notification) => !notification.is_read);

  const ordersNew = () => orders.filter((order) => !order.is_read);

  return (
    <PageContentStyle className="page-home">
      <PageTitle type="home" />
      {/* <DriverInfo info={userInfo} /> */}
      {/* <DividingLine /> */}
      <br />

      {/* 新着配送指示 */}
      <PageTitle type="order_new" />
      <ul>
        {ordersNew().map((order, index) => (
          <OrderItemHome
            key={order.id}
            // title={order.destination}
            // order_date={order.order_date}
            // plan_time={order.plan_time}
          />
        ))}
      </ul>
      <DividingLine />
      {/* 新着通知 */}
      <PageTitle type="notification_new" />
      <ul>
        {/* {notificationsNew().map((notification, index) => (
          <NotificationItem item={notification} key={notification.id} />
        ))} */}
      </ul>
    </PageContentStyle>
  );
}
