import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import { Button, IconButton } from '@mui/material';
import { ActionBtn } from 'utils/types';

import 'styles/PositionAction.scss';

type Props = {
  openCancelForm: () => void;
  actionBtn: () => ActionBtn;
  toggleWaiting: (isWaiting: boolean) => void;
  showDrawerConflict?: () => void;
};
export function PositionAction({ openCancelForm, actionBtn, toggleWaiting, showDrawerConflict }: Props) {
  const updatePositionStatus = () => {
    const { disable, inProgressPosition, idx } = actionBtn();
    if (disable) return;
    const isOtherInProgress = (inProgressPosition as { idx: string })?.idx !== idx;
    if (isOtherInProgress && showDrawerConflict) {
      showDrawerConflict();
      return;
    }
    console.log('updatePositionStatus');
  };
  return (
    <div className="position-action-btns">
      <IconButton className="cancel-btn" color="warning" onClick={openCancelForm}>
        <ReportProblemIcon />
      </IconButton>
      {(actionBtn().status === '配送開始' || actionBtn().status === '待機完了') && (
        <IconButton className="pause-btn" color="warning" onClick={() => toggleWaiting(true)}>
          <PauseIcon />
          <span className="sub-info -pause">待機</span>
        </IconButton>
      )}
      {actionBtn().status === '待機開始' && (
        <IconButton className="play-btn" onClick={() => toggleWaiting(false)}>
          <PlayArrowIcon />
          <span className="sub-info -play">待機終了</span>
        </IconButton>
      )}
      <Button
        className={`swipe-btn -${actionBtn().type} ${actionBtn().disable ? 'disable' : ''}`}
        variant="contained"
        disableElevation
        fullWidth
        startIcon={<SwipeRightIcon />}
        disabled={actionBtn().disable}
        onClick={updatePositionStatus}
      >
        {actionBtn().text}
      </Button>
    </div>
  );
}
