import noNotification from 'assets/img/no_notification.png';
import noFutureOrder from 'assets/img/no_order_future.png';
import noPastOrder from 'assets/img/no_order_past.png';
import noTodayOrder from 'assets/img/no_order_today.png';
import styled from 'styled-components';
import { c } from 'styles/valiables';

const NoItemStyle = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: -200px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img-wrapper {
    width: 100%;
    padding: 30px;
    img {
      width: 100%;
      height: auto;
    }
  }
  p {
    font-size: 1.2rem;
    color: ${c.grey};
    margin: 0.5rem;
  }
`;
type Props = {
  type: 'notification' | 'unread' | 'today' | 'past' | 'not_found';
};

export function NoItem(props: Props) {
  const { type } = props;
  const imgSrcMap = {
    notification: noNotification,
    unread: noFutureOrder,
    today: noTodayOrder,
    not_found: noTodayOrder,
    past: noPastOrder
  };

  const imgSrc = imgSrcMap[type] || '';

  const textMap = {
    notification: '通知がまだありません',
    unread: '未読の配送指示がありません',
    today: '本日の配送指示がありません',
    past: '過去の配送指示がありません',
    not_found: 'ページが見つかりません'
  };
  const text = textMap[type] || '';

  return (
    <NoItemStyle>
      <div className="img-wrapper">
        <img src={imgSrc} alt="" />
      </div>
      <p>{text}</p>
    </NoItemStyle>
  );
}
