import styled from 'styled-components';
import { c } from 'styles/valiables';

const HrStyle = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0 20px 0;
  background-color: ${c.dark50};
`;
export function DividingLine() {
  return <HrStyle />;
}
